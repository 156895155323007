import { useTranslation } from 'react-i18next'

import Button from '@/shared/components/button/Button'

import sokarLogo from '../../../assets/sokar_logo.png'
import { ProductCardContent, ProductCardImage, ProductCardWrapper } from './productCardElements'

export interface IProductCard {
  name: string
  description: string
  price: number
  onCheckout: (productId: string) => Promise<void>
  index: number
}

export const ProductCard = ({ name, description, price, onCheckout, index }: IProductCard) => {
  const { t } = useTranslation()

  return (
    <ProductCardWrapper
      initial={{
        opacity: 0,
        x: index % 2 === 0 ? 50 : -50,
      }}
      whileInView={{
        opacity: 1,
        x: 0,
        transition: {
          duration: 0.6,
        },
      }}
      viewport={{ once: true }}
    >
      <ProductCardImage>
        <img
          src={sokarLogo}
          alt='sokar logo'
        />
      </ProductCardImage>
      <ProductCardContent>
        <span className='product-card-text'>
          <h1>{name}</h1>
          <p>{description}</p>
        </span>
        <span className='product-card-footer'>
          <p>
            {t('products.price')}: {price}
          </p>
          <Button
            label={t('products.buy')}
            size='md'
            onClick={() => onCheckout(name)}
            animations={['tap', 'hover', 'gradient']}
          />
        </span>
      </ProductCardContent>
    </ProductCardWrapper>
  )
}
